<template>
  <BaseElSelect
    v-model="syncModel"
    filterable
    remote
    clearable
    reserve-keyword
    placeholder="請選擇抽獎名稱"
    no-match-text="無匹配項目"
    no-data-text="暫無數據"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    @change="$emit('change')"
    @clear="getMemberGame"
  >
    <BaseElSelectOption
      v-for="(item, index) in memberGameList"
      :key="`${index} - ${item.name}`"
      :label="item.name"
      :value="item"
    />
  </BaseElSelect>
</template>
<script>
import { GetMemberGame } from '@/api/lottery/memberGame'
import { mapGetters } from 'vuex'
export default {
  name: 'MemberGameSearch',
  props: ['model', 'type'],

  computed: {
    ...mapGetters(['shop']),
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  data: () => ({
    loading: false,
    memberGameList: [],
  }),

  async mounted () {
    await this.getMemberGame()
  },

  methods: {
    async remoteSearch (query) {
      query = query.trim()
      if (!query) this.membersList = []

      try {
        this.loading = true
        setTimeout(async () => {
          await this.getMemberGame(query)
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

    async getMemberGame (query = null) {
      const [res, err] = await GetMemberGame({
        shopId: this.shop,
        name: query || undefined,
        limit: 50,
      })
      if (err) return this.$logger.danger(err)
      this.memberGameList = res
      console.log(res)
    },
  },
}
</script>

<style scoped lang="scss"></style>
